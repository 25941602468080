@import "../../../styles//variables";

.container {
  iframe {
    width: 100%;
    height: 100%;
  }
  p {
    font-size: 18px;
  }
}

.videoWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  height: 400px;
  iframe {
    max-width: 700px;
  }
  @media (max-width: $breakpoint-small) {
    height: 200px;
  }
}

.image {
  padding-top: 78px;
  display: flex;
  color: var(--green);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fffefc;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("https://storage.googleapis.com/slanchevdom/home/optimize_home3.JPG");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
  height: 560px;
  @media (max-width: $breakpoint-medium) {
    height: 420px;
  }
}

.adres {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;

  h3 {
    width: 90%;
    padding-bottom: 8px;
    text-align: center;
    color: var(--green);
    box-shadow: 0 2px 2px -2px gray;
    margin: 18px;
    span {
      font-size: 22px;
    }
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;

  h2 {
    color: var(--green);
  }
  
  p {
    line-height: 1.5;
    max-width: 80%;
    @media (max-width: $breakpoint-small) {
      max-width: 95%;
    }
  }
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 16px;
  margin-bottom: 28px;
  @media (max-width: $breakpoint-small) {
    grid-template-columns: repeat(1, 1fr); /* 2 images per row on mobile */
  }
}

.imageItem {
  overflow: hidden;
  height: 200px;
  border: 4px solid var(--orange);
  border-radius: 20px;
}

.imageItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
