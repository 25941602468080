@import "../../../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  // background-color: var(--orange);
  padding: 40px 0;
  h1 {
    margin-top: 0;
  }
}

.location {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 90%;
  @media (max-width: $breakpoint-medium) {
    width: 100%;
  }
}

.line {
  width: 100%;
  height: 2px;
  background-color: rgba(128, 128, 128, 0.361);
  margin: 0 28px;
  @media (max-width: $breakpoint-medium) {
    margin: 0 14px;
  }
}

.text {
  color: rgb(56, 56, 56);
  // font-size: 28px;
  font-size: 1.5em;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
  h3 {
    margin: 0;
    color: var(--green);
  }
  h5{
    margin: 0;
    color: var(--green);
  }
  @media (max-width: $breakpoint-medium) {
    // font-size: 18px;
  }
}

.imageWrap {
  width: 80%;
  height: 300px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.imageContainer {
  width: 90%;
  height: 400px;
  position: relative;
  overflow: hidden;
  cursor: pointer; // Makes the cursor pointer for the whole image area

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: all 0.4s ease-in-out; // Add smooth transition for the image, increased to 0.4s for the zoom effect
  }

  // Add a darker overlay and zoom on hover
  &:hover img {
    filter: brightness(70%); // Darkens the image on hover
    transform: scale(1.1); // Adds a zoom effect
  }

  // Hover Button Style and Animation
  .hoverButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;

    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    background-color: var(--color14);
    color: var(--white);
    border: 1px solid var(--white);
    cursor: pointer;
    box-shadow: rgba(176, 181, 186, 0.064) 0px 8px 22px;
  }

  &:hover .hoverButton {
    opacity: 1;
    pointer-events: auto;
    transform: translate(-50%, -50%) scale(1.02);
  }
}
