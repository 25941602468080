@import "../../../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 40px 0 40px;
  p {
    font-size: 18px;
    line-height: 1.5;
  }
  strong {
    font-size: 20px;
    color: var(--green);
  }
  ul {
    list-style-position: inside;
    padding: 0;
    li {
      font-weight: 500;
      padding-bottom: 8px;
    }
  }

  @media (max-width: $breakpoint-medium) {
    padding: 20px 20px 0 20px;
  }
}
