.container {
  color: var(--dark-gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px 80px 20px;
  h1 {
    font-size: 32px;
    font-weight: 400;
  }
  p {
    text-align: center;
    font-size: 18px;
    strong {
      font-size: 22px;
    }
  }
  a {
    color: #4caf50;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.header {
  text-align: center;
  color: var(--green);
}

.horizontalLine {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 1px;
  background-color: rgb(195, 195, 195);
  margin: 20px 0;
  border: none;
}

.phone {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  p {
    font-family: "Roboto Slab", serif;
    font-size: 32px;
    font-weight: 400;
    margin: 10px 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    p {
      font-size: 24px;
      margin: 0;
    }
  }
}

.phoneLink {
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-gray);
  text-decoration: none;
}

form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin-top: 20px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  padding: 10px;
  border: 1px solid var(--green);
  font-size: 14px;
  transition: background-color 0.3s ease;

  box-shadow: rgba(176, 181, 186, 0.064) 0px 8px 22px;
}

textarea {
  resize: vertical;
  height: 100px;
  min-height: 100px;
}

input[type="submit"] {
  padding: 10px;
  background-color: var(--white);
  color: var(--green);
  border: 1px solid var(--green);
  font-size: 16px;
  cursor: pointer;
  box-shadow: rgba(176, 181, 186, 0.064) 0px 8px 22px;
  transition: 0.1s ease-in-out all;
}

input[type="submit"]:hover {
  background-color: var(--green);
  color: var(--white);
}

.row-two {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus {
  border: 1px solid var(--green);
  outline: none;
}

input::placeholder {
  color: #a9a9a9;
  font-family: "Roboto Slab", serif;
}

textarea::placeholder {
  color: #a9a9a9;
  font-family: "Roboto Slab", serif;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.status {
  font-size: 16px;
  margin-top: 20px;
  color: green;
}

.status.error {
  color: red;
}
