@import "../../../styles/variables";

.infoContainer {
  display: flex;
  @media (max-width: $breakpoint-medium) {
    flex-direction: column;
  }
}
.infoBox {
  width: 100%;
  // color: var(--orange);
  background-color: var(--color4);
  text-align: center;
  box-shadow: 0 0 20px rgba(214, 215, 216, 0.3);
  padding: 20px 0 30px 0;
  svg {
    font-size: 28px;
    color: var(--green);
  }
  h3 {
    color: var(--black);
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
    font-size: 20px;
    color: var(--green);
  }
}
.iframeWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  iframe {
    width: 90%;
    max-width: 600px;
    height: 303px;
  }
}
