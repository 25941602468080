@import "../../../../styles/variables";

.container {
  background: var(--orange);
  position: fixed;
  top: 70px;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 40px;
}

.content {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
    color: var(--white);
    font-size: 1.3rem;
    margin-bottom: 18px;
    transition: color 0.3s ease;
    font-weight: 500;
  }
}

.navbarIcons {
  font-size: 2rem;
  padding: 12px;
  color: var(--white);

  @media (min-width: $breakpoint-medium) {
    display: none;
  }
}

.overflowHidden {
  overflow: hidden;
}
