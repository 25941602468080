// PrivacyPolicy.module.scss
.container {
  width: 100%;
  max-width: 800px;
  margin: 80px auto;
  padding: 20px;
  // background-color: #f9f9f9;
  border-radius: 8px;
  // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  h2 {
    font-size: 1.5rem;
    color: #4caf50;
    margin-top: 20px;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }
  a {
    color: var(--dark-green);
    text-decoration: none;
  }
}

.header {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.updatedDate {
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin-bottom: 30px;
}

.list {
  list-style-type: disc;
  margin-left: 20px;
  color: #555;
}
