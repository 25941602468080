@import "variables";

$black-color: #000000;
$white-color: #ffffff;
$gray-color: #6d6d6d;
$dark-gray-color: #1e1e1e;

$color-1: #c58940;
$color-2: #e5ba73;
$color-3: #faeab1;
$color-4: #f5f9f5;

$color-5: rgba(255, 255, 255, 0.95);
$color-6: #c38154;
$color-7: #613d28;
$color-8: #6b240c;

$orange: #ffd09b;
$dark-orange: #e6b57f;
$green: #33cc33;
$dark-green: #2bab2b;

$color-9: #347928;
$color-10: #c0eba6;
$color-11: #fccd2a;
$color-12: #fffbe6;
$color-13: #66ff00;
$color-14: #96cd4b;
$color-15: #8dc641;

$color-16: #ffac4e;
$color-17: #f5f9f5;

$gold-color: #e3d17b;
$red-color: #a93729;
$black-background: #1e1e1e;
$light-gray-progress-background: #d9d9d9;
$placeholder-gray-color: #d9d9d9;
$gold-gradient: linear-gradient(180deg, #faeab1 0%, #e5ba73 100%);
$green-gradient: linear-gradient(180deg, #28b492 0%, #02614a 100%);
$red-gradient: linear-gradient(180deg, #b03d2d 0%, #6a0909 100%);

$green-gradient: linear-gradient(180deg, #98fb98 0%, #66cdaa 100%);
$dark-green-gradient: linear-gradient(180deg, #006400 0%, #013220 100%);

$orange-gradient: linear-gradient(
  180deg,
  #d14747 0%,
  #dd6e18 0.01%,
  #6c0400 100%
);
$black-gradient: linear-gradient(
  180deg,
  rgba(0, 0, 0, 1) 0%,
  rgba(62, 58, 34, 1) 100%,
  rgb(53, 49, 29) 100%,
  rgb(80, 74, 47) 100%,
  rgba(227, 209, 123, 1) 100%
);

$form-background: rgb(15, 15, 15);
$form-text: #c5c5c5;
$form-input-background: #212121;
$form-input-background-focus: #282828;

:root {
  --black: #{$black-color};
  --white: #{$white-color};
  --gray: #{$gray-color};
  --dark-gray: #{$dark-gray-color};

  --color1: #{$color-1};
  --color2: #{$color-2};
  --color3: #{$color-3};
  --color4: #{$color-4};

  --color5: #{$color-5};
  --color6: #{$color-6};
  --color7: #{$color-7};
  --color8: #{$color-8};

  --orange: #{$orange};
  --dark-orange: #{$dark-orange};
  --green: #{$green};
  --dark-green: #{$dark-green};

  --color9: #{$color-9};
  --color10: #{$color-10};
  --color11: #{$color-11};
  --color12: #{$color-12};
  --color13: #{$color-13};
  --color14: #{$color-14};
  --color15: #{$color-15};

  --color16: #{$color-16};
  --color17: #{$color-17};

  --gold: #{$gold-color};
  --red: #{$red-color};
  --blackBackground: #{$black-background};

  --green-gradient: #{$green-gradient}; // Light Green Gradient
  --dark-green-gradient: #{$dark-green-gradient}; // Dark Green Gradient

  --gold-gradient: #{$gold-gradient};
  --green-gradient: #{$green-gradient};
  --red-gradient: #{$red-gradient};
  --black-gradient: #{$black-gradient};
  --form-background: #{$form-background};
  --form-text: #{$form-text};
  --form-input-background: #{$form-input-background};
  --form-input-background-focus: #{$form-input-background-focus};
  --gray: #{$placeholder-gray-color};
  --orange-gradient: #{$orange-gradient};
}
