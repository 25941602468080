@import "../../../styles/variables";

.aboutUs {
  margin-bottom: 40px;
  .content {
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
    @media (max-width: $breakpoint-medium) {
      padding: 0 20px;
    }
    h2 {
      color: var(--green);
      font-size: 26px;
      strong {
        font-family: "Montserrat", sans-serif;
      }
    }
    h3{
      font-size: 26px;
      color: var(--green);
    }
    p {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 15px;
      strong {
      }
    }
  }
  iframe {
    margin-top: 40px;
    width: 100%;
    min-height: 300px;
    height: auto;
  }
}
.greenText{
  color: var(--green);
  font-weight: 600;
}