@import "../../../styles/variables";

.container {
  font-family: "Roboto Slab", serif;
  font-weight: 300; // Lighter font-weight
  font-size: 14px;
  line-height: 1.5;
  transition: background-color 0.3s ease-in-out;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  @media (max-width: $breakpoint-medium) {
    background: var(--orange);
    justify-content: space-between;
  }
}

.containerActive {
  @extend .container;
  background: var(--orange);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 10px;
  @media (max-width: $breakpoint-medium) {
    justify-content: space-between;
  }
}

.leftLink {
  display: flex;
  @media (max-width: $breakpoint-medium) {
    display: none;
  }
}

.navLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  margin: 0 60px;
  @media (max-width: $breakpoint-large) {
    margin: 0;
  }
  .logoImage {
    margin-top: 6px;
    max-height: 78px;
    @media (max-width: $breakpoint-large) {
      max-height: 78px;
      padding: 20px;
    }
  }

  .logoText {
    margin: 0;
    font-size: 8px;
  }
}

.rightLink {
  display: flex;
  @media (max-width: $breakpoint-medium) {
    display: none;
  }
}

.link {
  display: flex;
  align-items: center;
  margin: 0 10px;

  a {
    position: relative;
    color: var(--white);
    text-decoration: none;
    padding: 8px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    @media (max-width: $breakpoint-large) {
      font-size: 12px;
    }
  }
  a:hover{
    text-decoration: none;
  }
  a:hover:after {
    background: linear-gradient(
      90deg,
      rgba(80, 252, 1, 0) 3%,
      var(--green) 50%,
      rgba(153, 248, 29, 0) 97%
    );
    border-radius: 1px;
    bottom: 0;
    content: "";
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
    height: 1px;
    left: -10px;
    position: absolute;
    right: -10px;
    transition: 0.3s ease-in-out;
  }
}

.linkActive {
  @extend .link;
  a {
    color: var(--white);
  }
}

.activeBright {
  position: relative;
  background: var(--green) !important;
  -webkit-background-clip: text !important; /* Safari/Chrome */
  background-clip: text !important; /* Standard syntax */
  color: transparent !important;
}

.activeDark {
  position: relative;
  background: var(--white) !important;
  -webkit-background-clip: text !important; /* Safari/Chrome */
  background-clip: text !important; /* Standard syntax */
  color: transparent !important;
}

.activeBright::after {
  background: linear-gradient(
    90deg,
    rgba(18, 252, 1, 0) 3%,
    var(--green) 50%,
    rgba(62, 248, 29, 0) 97%
  );
  border-radius: 1px;
  bottom: 0;
  content: "";
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
  height: 1px;
  left: -10px;
  position: absolute;
  right: -10px;
}

.activeDark::after {
  background: linear-gradient(
    90deg,
    rgba(22, 252, 1, 0) 3%,
    var(--green) 50%,
    rgba(95, 248, 29, 0) 97%
  );
  border-radius: 1px;
  bottom: 0;
  content: "";
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
  height: 1px;
  left: -10px;
  position: absolute;
  right: -10px;
}
