@import "../../../../styles/variables";

.container {
  display: flex;
  margin: auto;
  flex-direction: column;
  h3 {
    color: var(--dark-green);
  }
  p {
    font-size: 18px;
    line-height: 1.6;
  }
  @media (max-width: $breakpoint-medium) {
    padding-top: 10px;
  }
}

.leftSide {
  font-size: 1.5em;
  line-height: 1.5;
  text-align: center;
  margin: auto;
  padding: 40px;

  @media (max-width: $breakpoint-medium) {
    padding: 10px;
  }
  // background-color: var(--white);
}
.rightSide {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  background-color: var(--orange);
  padding: 40px 0;
  iframe {
    width: 65%;
    height: 387px;
    @media (max-width: $breakpoint-medium) {
      width: 95%;
      height: 200px;
    }
  }
  @media (max-width: $breakpoint-medium) {
    padding: 20px 0;
  }
}
