@import "../../../styles/variables";

.grid {
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 images per row */
  gap: 18px; /* Space between images */
  justify-items: center;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr); /* 4 images per row */
  }
  @media (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr); /* 4 images per row */
  }
  @media (max-width: 556px) {
    grid-template-columns: repeat(1, 1fr); /* 4 images per row */
  }
}

.gridImage {
  width: 100%;
  height: 240px;
  cursor: pointer;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  border: 4px solid var(--orange);
  border-radius: 8px;

  &:hover {
    transform: scale(1.02); /* Zoom effect on hover */
  }
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 30px;
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1001;
}

@media (max-width: 768px) {
  .lightbox {
    padding: 5px;
  }

  .lightbox img {
    max-width: 90vw;
    max-height: 80vh;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    padding: 5px;
    background: none;
    color: white;
    border: none;
    cursor: pointer;
  }
}
