@import "../../../../styles/variables";

.container {
  max-width: 900px;
  margin: 60px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  @media (max-width: $breakpoint-medium) {
    grid-template-columns: 1fr;
  }
}

.infoBoxSingle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: var(--green);
  box-shadow: 0 2px 2px -2px gray;
  height: auto;
  margin: 10px;
  p {
    text-align: center;
    color: var(--dark-gray);
    font-weight: 500;
    font-size: 18px;
  }
  ul {
    font-size: 18px;
    color: var(--dark-gray);
    list-style-position: inside;
    padding: 0;
    font-weight: 600;
  }
  a {
    text-decoration: none;
    color: var(--color7);
  }
  svg {
    font-size: 4em;
    margin: 0 10px;
  }
  @media (max-width: $breakpoint-medium) {
    flex-direction: column;
    height: auto;
    text-align: center;
  }
}

.infoBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--green);
  box-shadow: 0 2px 2px -2px gray;
  height: auto;
  margin: 10px;
  p {
    color: var(--dark-gray);
    font-size: 18px;
    font-weight: 600;
  }
  a {
    text-decoration: none;
    color: var(--color7);
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
    gap: 20px;
    li {
      padding: 6px;
    }
    @media (max-width: $breakpoint-medium) {
      flex-direction: column;
    }
  }
  svg {
    font-size: 4em;
    margin: 0 10px;
  }
  @media (max-width: $breakpoint-medium) {
    flex-direction: column;
    height: auto;
    text-align: center;
  }
}
