@import "../../../styles/variables";

.footer {
  font-family: "Roboto Slab", serif;
  background-color: var(--orange);
  color: var(--green);
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -2px 29px rgba(100, 100, 111, 0.2);
  @media (max-width: $breakpoint-large) {
    flex-direction: column;
    align-items: center;
  }
  a {
    color: var(--green);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: $breakpoint-large) {
    flex-direction: column;
    align-items: center;
  }
}

.privacyPolicy {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    font-size: 12px;
    color: var(--green);
    margin: 8px;
  }
}

.logoAndLinks {
  display: flex;
  align-items: center;
  img {
    padding-left: 20px;
    @media (max-width: $breakpoint-large) {
      padding-left: 0px;
    }
  }
  p {
    font-size: 18px;
  }
  .logo {
    max-width: 200px;
    margin-right: 20px;

    @media (max-width: $breakpoint-large) {
      margin-right: 0px;
    }
  }

  .navLinks {
    @media (max-width: $breakpoint-large) {
      flex-direction: column;
      align-items: center; /* Center items in column layout */
    }

    a {
      color: var(--green);
      text-decoration: none;
      margin: 0 10px;
      transition: color 0.3s ease;

      &:hover {
        color: var(--green);
      }

      @media (max-width: $breakpoint-large) {
        margin: 5px 0; /* Adjust margin for smaller screens */
      }
    }
  }
}

.contactAndSocial {
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-large) {
    flex-direction: column;
    align-items: center;
  }

  .contactInfo {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: $breakpoint-large) {
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
  }

  .location,
  .phone,
  .email {
    display: flex;
    align-items: center;
    padding: 12px 0px;
    font-size: 16px;

    a {
      color: var(--green);
      text-decoration: none;
    }

    svg {
      font-size: 20px;
      margin-right: 5px;
    }
  }
  .upravitel {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 10px;
    p {
      margin: 6px;
    }
  }

  .upravitelText {
    display: flex;
    align-items: center;
    gap: 8px;
    strong {
      font-size: 18px;
    }
    @media (max-width: $breakpoint-medium) {
      flex-direction: column;
      gap: 0;
    }
  }

  .socialLinks {
    display: flex;
    align-items: center;

    a {
      color: var(--green);
      text-decoration: none;
      margin: 0 10px;
      transition: color 0.3s ease;

      &:hover {
        color: var(--dark-green);
      }
    }
  }
}
.link{
  color: var(--white) !important;
}