@import "../styles/variables";

:global {
  .image-gallery-icon {
    color: green; /* Change arrow color to green */
    stroke: green; /* Change the stroke of the SVG */
  }

  .image-gallery-icon:hover {
    color: limegreen; /* Brighter green on hover */
    stroke: limegreen; /* Brighter stroke on hover */
  }

  .image-gallery-icon.image-gallery-left-nav,
  .image-gallery-icon.image-gallery-right-nav {
    color: green; /* Set the arrow color to green */
    stroke: green; /* Change SVG stroke color */
  }

  .image-gallery-icon.image-gallery-left-nav:hover,
  .image-gallery-icon.image-gallery-right-nav:hover {
    color: limegreen; /* Brighter green on hover */
    stroke: limegreen; /* Brighter green stroke on hover */
  }
}

.section {
  background-color: var(--orange);
  padding-top: 78px;
  height: 320px;
  @media (max-width: $breakpoint-small) {
    height: 200px;
  }
}

.sectionTitle {
  background-color: #fffefc;
  display: flex;
  color: var(--green);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)),
    url("https://storage.googleapis.com/slanchevdom/home/optimize_home2.JPG");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);

 
  h1 {
    letter-spacing: 1px;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    font-size: 2em;
    font-weight: 800;
  }
  p {
    text-align: center;
    width: 50%;
  }
  @media (max-width: $breakpoint-small) {
    // background-image: none;
    border-top-left-radius: 50%;
    p {
      width: 80%;
    }
  }
}
