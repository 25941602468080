@import "../../../../styles/variables";

.page {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 650px;
  overflow: hidden;
  @media (max-width: $breakpoint-large) {
    height: 70vh;
    background-color: var(--orange);
  }
  @media (max-width: $breakpoint-medium) {
    height: 70vh;
  }
  @media (max-width: 376px) {
    height: 78vh;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 1s ease-in-out;

  @media (max-width: $breakpoint-medium) {
    top: 78px;
    border-top-left-radius: 50%;
    // border-top-right-radius: 25%;
  }
}

.currentImage {
  opacity: 1;
  z-index: 1;
}

.nextImage {
  opacity: 0;
  z-index: 0;
}

.fadeIn {
  opacity: 1;
  z-index: 2;
}

.container {
  position: relative; // Make sure the text and content stay above the images
  z-index: 3; // Ensure content is above the images
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  width: 80%;
  text-align: right;
  margin-right: 5rem;

  h1 {
    font-size: 5.5rem;
    max-width: 87%;
    margin: 0 0 20px 0;
    color: var(--green);
    text-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
  }
  p {
    font-size: 1.5rem;
    color: var(--color4);
    text-shadow: -1px 1px 4px rgba(0, 0, 0, 0.5);
  }

  button {
    margin: 0;
  }

  @media (max-width: $breakpoint-medium) {
    width: 100%;
    margin: 0 20px 0 0;
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 18px;
    }
  }
  @media (max-width: $breakpoint-large) {
    width: 100%;
    margin: 0 20px 0 0;
    h1 {
      font-size: 38px;
      padding-left: 10px;
    }
    p {
      font-size: 20px;
    }
  }
}
