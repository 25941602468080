.callButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--orange);
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1000;
  text-decoration: none;

  &:hover {
    background-color: #e6b57f;
  }
}

.icon {
  font-size: 24px;
}
